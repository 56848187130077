export type DropdownPosition =
  | "top-left"
  | "top-right"
  | "bottom-left"
  | "bottom-right"
  | "right-bottom"
  | "left-bottom";

export const useDropdownPosition = (
  position: DropdownPosition,
  options?: { offset?: boolean },
) => {
  return {
    class: computed(() => {
      const bottom =
        (options?.offset ?? true)
          ? "top-[calc(100%+10px)]"
          : "top-[calc(100%-1px)]";
      const top =
        (options?.offset ?? true)
          ? "bottom-[calc(100%+10px)]"
          : "bottom-[calc(100%-1px)]";

      const right =
        (options?.offset ?? true)
          ? "left-[calc(100%+10px)]"
          : "left-[calc(100%-1px)]";

      const left =
        (options?.offset ?? true)
          ? "right-[calc(100%+10px)]"
          : "right-[calc(100%-1px)]";

      if (position === "top-left") return [top, "left-0"];
      if (position === "top-right") return [top, "right-0"];
      if (position === "bottom-left") return [bottom, "left-0"];
      if (position === "bottom-right") return [bottom, "right-0"];
      if (position === "right-bottom") return ["bottom-0", right];
      if (position === "left-bottom") return ["bottom-0", left];
    }),
  };
};
